import React, { useEffect, useCallback, useState } from "react";

import {
  Button,
  TextField,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import MDBox from "components/MDBox";

import { Controller, useForm } from "react-hook-form";

import {
  FormContainer,
  TextFieldElement,
  SelectElement,
  CheckboxElement,
} from "react-hook-form-mui";

import { getShifts, getBrands, getDepartments } from "helper";

import PropTypes from "prop-types";

function AddDialog({ open, employeeId, type, defaultValues, onClose, onSubmit }) {
  const { control, handleSubmit, reset } = useForm();

  const [shiftsOptions, setShiftsOptions] = useState([]);
  const [departmentsOptions, setDepartmentsOptions] = useState([]);
  const [brandsOptions, setBrandsOptions] = useState([]);

  const handleClose = () => {
    onClose && onClose();
  };

  useEffect(() => {
    console.log("defaultValues", defaultValues);

    fetchShiftsData();
    fetchDepartmentData();
    fetchBrandsData();
  }, []);

  useEffect(() => {
    // Set the default values when the component mounts
    reset(defaultValues);
  }, [defaultValues, reset]);

  const fetchShiftsData = useCallback(async () => {
    try {
      const res = await getShifts({ limit: 50, sortBy: "isDefault:desc,createdAt:desc" });

      const { results } = res.data;

      const formattedData = results.map((shift) => {
        return { id: shift.id, label: shift.title };
      });

      setShiftsOptions(formattedData);
    } catch (error) {
      console.log("error", error);
    }
  }, []);

  const fetchDepartmentData = useCallback(async () => {
    try {
      const res = await getDepartments({ limit: 50, sortBy: "createdAt:desc" });

      const { results } = res.data;

      const formattedData = results.map((department) => {
        return { id: department.id, label: department.name };
      });

      setDepartmentsOptions(formattedData);
    } catch (error) {
      console.log("error", error);
    }
  }, []);

  const fetchBrandsData = useCallback(async () => {
    try {
      const res = await getBrands({ limit: 50, sortBy: "createdAt:desc" });

      const { results } = res.data;

      const formattedData = results.map((brand) => {
        return { id: brand.id, label: brand.name };
      });

      setBrandsOptions(formattedData);
    } catch (error) {
      console.log("error", error);
    }
  }, []);

  const handleFormSubmit = (data) => {
    //const task = data.task !== defaultValues.task ? data.task : null;

    if (type === "add") {
      onSubmit && onSubmit({ ...data, type });
    } else {
      const { name, shift, email, dailyReports, employeeRole, department, brand } = data;

      let updatedData = {};

      if (name && name !== defaultValues.name) {
        updatedData["name"] = name;
      }

      if (shift && shift !== defaultValues.shift) {
        updatedData["shift"] = shift;
      }

      if (department && department !== defaultValues.department) {
        updatedData["department"] = department;
      }

      if (brand && brand !== defaultValues.brand) {
        updatedData["brand"] = brand;
      }

      if (email && email !== defaultValues.email) {
        updatedData["email"] = email;
        updatedData["dailyReports"] = dailyReports === undefined ? true : dailyReports;
      } else if (dailyReports !== undefined && dailyReports !== defaultValues.dailyReports) {
        updatedData["dailyReports"] = dailyReports;
      }

      if (employeeRole !== undefined && employeeRole !== defaultValues.employeeRole) {
        updatedData["employeeRole"] = employeeRole;
      }

      onSubmit && onSubmit({ ...updatedData, type, employeeId });
    }

    // reset();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <FormContainer
        control={control}
        defaultValue={defaultValues}
        onSuccess={handleSubmit(handleFormSubmit)}
      >
        <DialogTitle>{type === "add" ? "Add Employee" : "Edit Employee"}</DialogTitle>

        <DialogContent>
          <DialogContentText>Please enter the required information.</DialogContentText>

          <MDBox display="flex" flexDirection="row-reverse">
            <CheckboxElement label="Daily Reports" control={control} name="dailyReports" />
          </MDBox>

          <TextFieldElement
            control={control}
            margin="dense"
            name="name"
            label="Employee Name"
            type="text"
            fullWidth
            variant="standard"
            required
          />

          <TextFieldElement
            control={control}
            margin="dense"
            name="email"
            label="Email"
            type="text"
            fullWidth
            variant="standard"
          />

          <TextFieldElement
            control={control}
            margin="dense"
            name="userId"
            label="userId"
            type="text"
            fullWidth
            variant="standard"
            disabled
          />

          <SelectElement
            control={control}
            margin="dense"
            label="Shift"
            name="shift"
            variant="standard"
            fullWidth
            options={shiftsOptions}
            required
          />

          <SelectElement
            control={control}
            margin="dense"
            label="Brand"
            name="brand"
            variant="standard"
            fullWidth
            options={brandsOptions}
            required
          />

          <SelectElement
            control={control}
            margin="dense"
            label="Department"
            name="department"
            variant="standard"
            fullWidth
            options={departmentsOptions}
            required
          />

          <SelectElement
            control={control}
            margin="dense"
            label="Role"
            name="employeeRole"
            variant="standard"
            fullWidth
            options={[
              { id: "employee", label: "Default" },
              { id: "adminEmployee", label: "Admin" },
              { id: "subAdminEmployee", label: "Sub Admin" },
            ]}
            required
          />

          <TextFieldElement
            control={control}
            margin="dense"
            name="cardno"
            label="cardno"
            type="number"
            fullWidth
            variant="standard"
            required
            disabled
          />

          <TextFieldElement
            control={control}
            margin="dense"
            name="uid"
            label="uid"
            type="number"
            fullWidth
            variant="standard"
            required
            disabled
          />
        </DialogContent>

        {/* </MDBox> */}

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Submit</Button>
        </DialogActions>
      </FormContainer>
    </Dialog>
  );
}

// Setting default values for the props of DataTable
AddDialog.defaultProps = {
  open: false,
  userId: null,
  defaultValues: {},
  onClose: null,
  onSubmit: null,
};

// Typechecking props for the DataTable
AddDialog.propTypes = {
  open: PropTypes.bool,
  type: PropTypes.oneOf(["add", "edit"]).isRequired,
  userId: PropTypes.string,
  defaultValues: PropTypes.object,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default AddDialog;
