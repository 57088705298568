/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useCallback } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React components

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import APIDataTable from "customComponents/Tables/APIDataTable";
import { useAlert } from "customComponents/Alert";

import AddDialog from "./AddDialog";

// Data

import PropTypes from "prop-types";
import { addShift, getShifts, deleteShift, editShift } from "helper";
import DashboardNavbar from "customComponents/Navbars/DashboardNavbar";
import DashboardLayout from "customComponents/LayoutContainers/DashboardLayout";
import Footer from "customComponents/Footer";

import { formatTime, isObjectEqual } from "../../utils";

function ShiftData({ shift }) {
  const [menu, setMenu] = useState(null);
  const [addDialog, setAddDialog] = useState(false);
  const alert = useAlert();

  const [shiftsData, setShiftsData] = useState([]);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [totalPages, setTotalPages] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [filter, setFilter] = useState({});

  const columns = [
    { Header: "title", accessor: "title", width: "20%", align: "left" },
    { Header: "startTime", accessor: "startTime", align: "center" },
    { Header: "duration", accessor: "duration", align: "center" },
    { Header: "graceTime", accessor: "graceTime", align: "center" },

    { Header: "action", accessor: "action", align: "center" },
  ];

  useEffect(() => {
    fetchData();
  }, [page, limit, filter]);

  const fetchData = useCallback(async () => {
    try {
      const { search, ...reset } = filter;
      let payload = { page, limit, ...reset, sortBy: "isDefault:desc,createdAt:desc" };

      if (search && search !== "") {
        payload["title"] = search;
      }

      const res = await getShifts(payload);

      const { results, totalPages, totalResults } = res.data;

      const formattedData = formatData(results);

      setShiftsData(formattedData);
      setTotalPages(totalPages);
      setTotalResults(totalResults);

      console.log(res);
    } catch (error) {
      console.log("error", error);

      const title = error.response ? error.response.statusText : "Unknown error";
      const message =
        error.response && error.response.data ? error.response.data.message : error.message;

      alert.show(title, message);
    }
  }, [page, limit, filter]);

  const addShiftRequest = useCallback(
    async (payload, onSuccess) => {
      try {
        await addShift(payload);

        fetchData();

        onSuccess && onSuccess();
      } catch (error) {
        console.log("Add Shift Error", error);

        const title = error.response ? error.response.statusText : "Unknown error";
        const message =
          error.response && error.response.data ? error.response.data.message : error.message;

        alert.show(title, message);
      }
    },
    [page, limit, filter]
  );

  const editShiftRequest = useCallback(
    async (id, payload, onSuccess) => {
      try {
        await editShift(id, payload);

        fetchData();

        onSuccess && onSuccess();
        alert.show("success", "Updated Successfully");
      } catch (error) {
        console.log("Edit Shift Error", error);

        const title = error.response ? error.response.statusText : "Unknown error";
        const message =
          error.response && error.response.data ? error.response.data.message : error.message;

        alert.show(title, message);
      }
    },
    [page, limit, filter]
  );

  const deleteShiftRequest = useCallback(
    async (id, onSuccess) => {
      try {
        await deleteShift(id);

        fetchData();

        onSuccess && onSuccess();
      } catch (error) {
        console.log("Delete Worklog Error", error);

        const title = error.response ? error.response.statusText : "Unknown error";
        const message =
          error.response && error.response.data ? error.response.data.message : error.message;

        alert.show(title, message);
      }
    },
    [page, limit, filter]
  );

  const formatData = (res) => {
    return res.map((value, index) => {
      const { title, startTime, duration, graceTime, isDefault } = value;

      return {
        title: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {title + (isDefault ? " - Default" : "")}
          </MDTypography>
        ),
        startTime: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {formatTime(startTime)}
          </MDTypography>
        ),
        duration: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {duration} hours
          </MDTypography>
        ),
        graceTime: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {graceTime} minutes
          </MDTypography>
        ),

        action: (
          <MDTypography
            component="a"
            color="text"
            id={value.id}
            data={JSON.stringify(value)}
            onClick={openMenu}
          >
            <Icon>more_vert</Icon>
          </MDTypography>
        ),
      };
    });
  };

  const openMenu = ({ currentTarget }) => {
    setMenu(currentTarget);
  };

  const closeMenu = () => {
    setMenu(null);
  };

  const openAddDialog = (selectedType = "add", id = null, defaultValues = {}) => {
    setAddDialog({ selectedType, id, defaultValues });
  };

  const closeAddDialog = () => {
    setAddDialog(null);
  };

  const handleAdd = () => {
    closeMenu();
    openAddDialog();
  };

  const handleEdit = async () => {
    closeMenu();

    const dataJson = menu.getAttribute("data");
    console.log(dataJson);
    const data = JSON.parse(dataJson);

    openAddDialog("edit", menu.id, data);
  };

  const handleDelete = () => {
    const id = menu.id;
    console.log("shift", id);

    closeMenu();

    alert.show("Remove Shift", `Are you sure you want to delete shift?`, ["No", "Yes"], (index) => {
      if (index === 1) {
        deleteShiftRequest(id);
      }
    });
  };

  const handleMakeDefault = () => {
    const id = menu.id;
    console.log("shift", id);

    const dataJson = menu.getAttribute("data");
    console.log(dataJson);
    const data = JSON.parse(dataJson);

    closeMenu();

    if (data.isDefault) {
      alert.show("Make Default", "This is already a default shift.");
      return;
    }

    alert.show(
      "Make Default",
      `Are you sure you want to make this shift default?`,
      ["No", "Yes"],
      (index) => {
        if (index === 1) {
          editShiftRequest(id, { isDefault: true }, null);
        }
      }
    );
  };

  const handleSubmit = (data) => {
    const { id, selectedType, ...rest } = data;

    if (selectedType === "add") {
      addShiftRequest(rest, closeAddDialog);
    } else {
      const { title, startTime, duration, graceTime } = rest;

      if (title || startTime || duration || graceTime) {
        editShiftRequest(id, rest, closeAddDialog);
      } else {
        alert.show("Edit Shift", "You must have at least one key to updete");
      }
    }
  };

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={handleEdit}>Edit</MenuItem>
      <MenuItem onClick={handleMakeDefault}>Make Default</MenuItem>
      <MenuItem onClick={handleDelete}>Delete</MenuItem>
    </Menu>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pb={3}>
        <Card>
          <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3} pb={0}>
            <MDBox>
              <MDTypography variant="h6">Devices</MDTypography>
            </MDBox>
            <MDBox>
              <MDTypography
                sx={{ cursor: "pointer", fontWeight: "bold" }}
                variant="h6"
                gutterBottom
                color="text"
                fontSize="small"
                onClick={handleAdd}
              >
                ADD SHIFT
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox>
            <APIDataTable
              table={{ rows: shiftsData, columns }}
              showTotalEntries={true}
              isSorted={false}
              noEndBorder
              entriesPerPage={false}
              canSearch
              currentPage={page}
              totalPages={totalPages}
              totalResults={totalResults}
              fetchData={(newPage, newLimit, newFilter) => {
                if (newPage !== page) {
                  setPage(newPage);
                }

                if (newLimit !== limit) {
                  setLimit(newLimit);
                }

                if (!isObjectEqual(newFilter, filter)) {
                  if (page !== 1 || newPage !== 1) {
                    setPage(1);
                  }

                  setFilter(newFilter);
                }
              }}
            />
          </MDBox>
          {renderMenu}
          {addDialog && (
            <AddDialog
              open={Boolean(addDialog)}
              {...addDialog}
              onClose={closeAddDialog}
              onSubmit={handleSubmit}
            />
          )}
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

ShiftData.defaultProps = {
  shift: null,
};

// Typechecking props for the DataTable
ShiftData.propTypes = {
  device: PropTypes.object,
};

export default ShiftData;
