import React, { useEffect } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import { useForm } from "react-hook-form";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import PropTypes from "prop-types";

function AddDialog({ open, id, selectedType, defaultValues, onClose, onSubmit }) {
  const { control, handleSubmit, getValues, reset, setValue } = useForm();

  const handleClose = () => {
    onClose && onClose();
  };

  useEffect(() => {
    // Set the default values when the component mounts
    reset(defaultValues);
  }, [defaultValues, reset]);

  const handleFormSubmit = (data) => {
    if (selectedType === "add") {
      onSubmit && onSubmit({ ...data, selectedType });
    } else {
      const { name, description } = data;

      let updatedData = {};

      if (name && name !== defaultValues.name) {
        updatedData["name"] = name;
      }

      if (description && description !== defaultValues.description) {
        updatedData["description"] = description;
      }

      onSubmit && onSubmit({ ...updatedData, selectedType, id });
    }

    // reset();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <FormContainer
        control={control}
        defaultValue={defaultValues}
        onSuccess={handleSubmit(handleFormSubmit)}
      >
        <DialogTitle>{selectedType === "add" ? "Add Brand" : "Edit Brand"}</DialogTitle>

        <DialogContent>
          <DialogContentText>Please enter the required information.</DialogContentText>
          <TextFieldElement
            control={control}
            margin="dense"
            name="name"
            label="Brand name"
            type="text"
            fullWidth
            variant="standard"
            required
          />

          <TextFieldElement
            control={control}
            margin="dense"
            name="description"
            label="Description"
            type="text"
            fullWidth
            variant="standard"
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Submit</Button>
        </DialogActions>
      </FormContainer>
    </Dialog>
  );
}

// Setting default values for the props of DataTable
AddDialog.defaultProps = {
  open: false,
  userId: null,
  defaultValues: {},
  onClose: null,
  onSubmit: null,
};

// Typechecking props for the DataTable
AddDialog.propTypes = {
  open: PropTypes.bool,
  selectedType: PropTypes.oneOf(["add", "edit"]).isRequired,
  userId: PropTypes.string,
  defaultValues: PropTypes.object,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default AddDialog;
