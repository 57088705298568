/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

// @mui material components

import { Button, Card } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { Controller, useForm } from "react-hook-form";

import { FormContainer, TextFieldElement, SelectElement } from "react-hook-form-mui";

import AutocompleteSearch from "customComponents/AutocompleteSearch";
import { getBrands, getDepartments } from "helper";

function ReportFilter({ onSubmit }) {
  const defaultValues = {};
  const { control, handleSubmit, resetField } = useForm();

  const [departmentsOptions, setDepartmentsOptions] = useState([]);
  const [brandsOptions, setBrandsOptions] = useState([]);

  const [showMoreOptions, setShowMoreOptions] = useState(false);

  useEffect(() => {
    fetchDepartmentData();
    fetchBrandsData();
  }, []);

  const fetchDepartmentData = useCallback(async () => {
    try {
      const res = await getDepartments({ limit: 50, sortBy: "createdAt:desc" });

      const { results } = res.data;

      const formattedData = results.map((department) => {
        return { id: department.id, label: department.name };
      });

      setDepartmentsOptions(formattedData);
    } catch (error) {
      console.log("error", error);
    }
  }, []);

  const fetchBrandsData = useCallback(async () => {
    try {
      const res = await getBrands({ limit: 50, sortBy: "createdAt:desc" });

      const { results } = res.data;

      const formattedData = results.map((brand) => {
        return { id: brand.id, label: brand.name };
      });

      setBrandsOptions(formattedData);
    } catch (error) {
      console.log("error", error);
    }
  }, []);

  const handleFormSubmit = (data) => {
    const { employees, ...rest } = data;

    let filter = { ...rest };

    if (employees && employees.length > 0) {
      filter["employeeIds"] = (employees ? employees.map((employee) => employee.id) : []).join(",");
    }

    onSubmit && onSubmit(filter);
  };

  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3} pb={2}>
        <MDTypography variant="h6">Filter</MDTypography>
      </MDBox>
      <MDBox
        display="flex"
        justifyContent="space-between"
        flex={1}
        alignItems="center"
        p={3}
        pt={0}
      >
        <FormContainer
          FormProps={{
            style: { display: "flex", flex: 1, flexDirection: "column" },
          }}
          control={control}
          defaultValue={defaultValues}
          onSuccess={handleSubmit(handleFormSubmit)}
        >
          <MDBox display="flex" flexDirection="row" justifyContent="left">
            <TextFieldElement
              InputLabelProps={{ shrink: true }}
              control={control}
              margin="dense"
              name="from"
              label="From"
              type="date"
              //   fullWidth
              variant="standard"
              required
              sx={{ paddingRight: 2, width: "25%" }}
            />

            <TextFieldElement
              InputLabelProps={{ shrink: true }}
              control={control}
              margin="dense"
              name="to"
              label="To"
              type="date"
              //   fullWidth
              variant="standard"
              required
              sx={{ paddingRight: 2, width: "25%" }}
            />

            <Controller
              control={control}
              name="employees"
              rules={{ required: false }}
              render={({ field: { onChange, value } }) => (
                <AutocompleteSearch
                  label={"Employee (Optional)"}
                  refreshKey={`${Boolean(defaultValues)}`}
                  value={value}
                  //   fullWidth={false}
                  apiUrl={"/v2/employees"}
                  filter={{}}
                  optionKey={"name"}
                  multiple
                  onOptionSelected={(resp) => {
                    onChange(resp);
                  }}
                />
              )}
            />
          </MDBox>

          {showMoreOptions && (
            <MDBox display="flex" flexDirection="row" justifyContent="left">
              <SelectElement
                control={control}
                margin="dense"
                label="Brand"
                name="brand"
                variant="standard"
                // fullWidth
                options={brandsOptions}
                sx={{ paddingRight: 2, width: "20%" }}
              />

              <SelectElement
                control={control}
                margin="dense"
                label="Department"
                name="department"
                variant="standard"
                // fullWidth
                options={departmentsOptions}
                sx={{ paddingRight: 2, width: "20%" }}
              />
            </MDBox>
          )}

          <MDBox display="flex" flexDirection="row" justifyContent="space-between">
            <Button
              type="button"
              onClick={() => {
                setShowMoreOptions(!showMoreOptions);

                if (showMoreOptions) {
                  resetField("brand");
                  resetField("department");
                }
              }}
              sx={{
                justifyContent: "left",
                p: 0,
                // color: "#000000",
                fontWeight: "400",
                textTransform: "unset",
              }}
            >
              {showMoreOptions ? "Show Less" : "Show More"}
            </Button>

            <Button type="submit" sx={{ p: 0 }}>
              Generate Report
            </Button>
          </MDBox>
        </FormContainer>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props
ReportFilter.defaultProps = {
  onSubmit: null,
};

// Typechecking props for the DataTable
ReportFilter.propTypes = {
  onSubmit: PropTypes.func,
};

export default ReportFilter;
