import axios from "axios";
import jwtClient from "jwtClient";
import { API_URL } from "../config";

async function getRequest(url, params) {
  return jwtClient.get(url, { params });
}

async function getEmployee(id) {
  return await jwtClient.get(`${API_URL}/v2/employees/${id}`);
}

async function getEmployees(params) {
  return jwtClient.get(`${API_URL}/v2/employees`, { params });
}

async function addEmployee(payload) {
  return jwtClient.post(`${API_URL}/v2/employees`, payload);
}

async function editEmployee(id, payload) {
  return jwtClient.patch(`${API_URL}/v2/employees/${id}`, payload);
}

async function deleteEmployee(id) {
  return jwtClient.delete(`${API_URL}/v2/employees/${id}`);
}

async function restoreEmployee(id) {
  return jwtClient.patch(`${API_URL}/v2/employees/${id}`, { isDeleted: false });
}

//Devices
async function getDevice(id) {
  return await jwtClient.get(`${API_URL}/v2/devices/${id}`);
}

async function getDevices(params) {
  return jwtClient.get(`${API_URL}/v2/devices`, { params });
}

async function addDevice(payload) {
  return jwtClient.post(`${API_URL}/v2/devices`, payload);
}

async function editDevice(resourceId, payload) {
  return jwtClient.patch(`${API_URL}/v2/devices/${resourceId}`, payload);
}

async function deleteDevice(resourceId) {
  return jwtClient.delete(`${API_URL}/v2/devices/${resourceId}`);
}

//Attendance
async function getAttendance(id) {
  return await jwtClient.get(`${API_URL}/v2/attendance/${id}`);
}

async function getAttendances(params) {
  return jwtClient.get(`${API_URL}/v2/attendance`, { params });
}

async function addAttendance(payload) {
  return jwtClient.post(`${API_URL}/v2/attendance`, payload);
}

async function editAttendance(Id, payload) {
  return jwtClient.patch(`${API_URL}/v2/attendance/${Id}`, payload);
}

async function deleteAttendance(Id) {
  return jwtClient.delete(`${API_URL}/v2/attendance/${Id}`);
}

async function bulkImportAttendance(payload) {
  return jwtClient.post(`${API_URL}/v2/attendance/bulk/import`, payload);
}

//Shifts
async function getShift(id) {
  return await jwtClient.get(`${API_URL}/v2/shifts/${id}`);
}

async function getShifts(params) {
  return jwtClient.get(`${API_URL}/v2/shifts`, { params });
}

async function addShift(payload) {
  return jwtClient.post(`${API_URL}/v2/shifts`, payload);
}

async function editShift(Id, payload) {
  return jwtClient.patch(`${API_URL}/v2/shifts/${Id}`, payload);
}

async function deleteShift(Id) {
  return jwtClient.delete(`${API_URL}/v2/shifts/${Id}`);
}

async function sendEmployeeReport(id, payload) {
  return jwtClient.post(`${API_URL}/v2/employees/${id}/sendReport`, payload);
}

async function getEmployeesReport(params) {
  return jwtClient.get(`${API_URL}/v2/reports/employees`, { params });
}

async function getEmployeesAvailabile(params) {
  return jwtClient.get(`${API_URL}/v2/reports/employeesAvailable`, { params });
}

async function getIndividualEmployeeReport(token) {
  const headers = {
    Authorization: "Bearer " + token,
  };

  return axios.get(`${API_URL}/v2/reports/employee`, { headers });
}

async function getAllEmployeesReport(params, token) {
  const headers = {
    Authorization: "Bearer " + token,
  };

  return axios.get(`${API_URL}/v2/reports/allEmployees`, { params, headers });
}

//Brands
async function getBrand(id) {
  return await jwtClient.get(`${API_URL}/v2/brands/${id}`);
}

async function getBrands(params) {
  return jwtClient.get(`${API_URL}/v2/brands`, { params });
}

async function addBrand(payload) {
  return jwtClient.post(`${API_URL}/v2/brands`, payload);
}

async function editBrand(Id, payload) {
  return jwtClient.patch(`${API_URL}/v2/brands/${Id}`, payload);
}

async function deleteBrand(Id) {
  return jwtClient.delete(`${API_URL}/v2/brands/${Id}`);
}

//Department
async function getDepartment(id) {
  return await jwtClient.get(`${API_URL}/v2/departments/${id}`);
}

async function getDepartments(params) {
  return jwtClient.get(`${API_URL}/v2/departments`, { params });
}

async function addDepartment(payload) {
  return jwtClient.post(`${API_URL}/v2/departments`, payload);
}

async function editDepartment(Id, payload) {
  return jwtClient.patch(`${API_URL}/v2/departments/${Id}`, payload);
}

async function deleteDepartment(Id) {
  return jwtClient.delete(`${API_URL}/v2/departments/${Id}`);
}

export {
  getRequest,
  getEmployee,
  getEmployees,
  addEmployee,
  editEmployee,
  deleteEmployee,
  restoreEmployee,
  getDevice,
  getDevices,
  addDevice,
  getAttendance,
  editDevice,
  deleteDevice,
  getAttendances,
  editAttendance,
  deleteAttendance,
  bulkImportAttendance,
  addAttendance,
  getShift,
  getShifts,
  addShift,
  editShift,
  deleteShift,
  sendEmployeeReport,
  getEmployeesReport,
  getEmployeesAvailabile,
  getIndividualEmployeeReport,
  getAllEmployeesReport,
  getBrand,
  getBrands,
  addBrand,
  editBrand,
  deleteBrand,
  getDepartment,
  getDepartments,
  addDepartment,
  editDepartment,
  deleteDepartment,
};
